import { inject, Injectable } from '@angular/core';
import { AppConfigService } from '@fuse/services/config';
import { TranslocoService } from '@jsverse/transloco';
import { ErrorResponse } from 'app/api/base-model';
import { UserService } from 'app/api/services/user.service';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, MessagePayload, onMessage, Messaging } from 'firebase/messaging';
import { environment } from '../../../environments/environment';
import { LoggerService } from './logger.service';

@Injectable({ providedIn: 'root' })
export class MessagingService {
    private app = initializeApp(environment.firebase);
    private readonly messaging: Messaging | null = null; // Initialize as null

    private _userService = inject(UserService);

    private _translocoService: TranslocoService = inject(TranslocoService);
    private _loggerService: LoggerService = inject(LoggerService);
    private _configService: AppConfigService = inject(AppConfigService);

    constructor() {
        // Initialize Firebase Messaging only if supported
        if (this.isPushSupported()) {
            try {
                this.messaging = getMessaging(this.app);
            } catch (error) {
                console.error('Failed to initialize Firebase Messaging:', error);
                this.messaging = null;
            }
        } else {
            console.log('Push notifications are not supported in this browser.');
        }
    }

    notificationPermission(): NotificationPermission {
        if (this.isPushSupported()) {
            try {
                return Notification.permission;
            } catch (e) {
                return 'denied';
            }
        }
        return 'denied';
    }

    // Request permission for notifications
    async requestPermission(): Promise<NotificationPermission> {
        if (!this.isPushSupported() || !this.messaging) {
            this.logMessageNotificationsDisabled();
            return 'denied';
        }

        try {
            if (this.notificationPermission() == 'denied') {
                this.logMessageNotificationsDisabled();
                return 'denied';
            } else if (this.notificationPermission() == 'default') {
                const notificationPermission = await Notification.requestPermission();
                if (notificationPermission === 'granted') {
                    return this.fetchFCMToken(false);
                }
            } else {
                return this.fetchFCMToken(false);
            }
        } catch (error) {
            this.logMessageNotificationsDisabled();
            return 'denied';
        }
    }

    // Listen for incoming messages
    listenForMessages() {
        if (!this.isPushSupported() || !this.messaging) {
            return;
        }

        const _permissionGranted = this.notificationPermission() == 'granted';
        if (_permissionGranted) {
            this.fetchFCMToken(true);
        }
        // Listen for push notifications
        onMessage(this.messaging, (payload) => {
            const notification = this.getNotificationObject(payload);

            if (notification == null) {
                // console.log('No notification object');
                return;
            }
            // Check if user is inside the app (suppress notification)
            if (document.visibilityState === 'visible') {
                console.log('User is inside the app, suppressing notification.');

                // Show an in-app alert instead of push notification
                // alert(`${notification.title}: ${notification.body}`);
                // const disabledMessage = this._translocoService.translate(
                //     'TRADING_PAIR.ALERT.NOTIFICATION.DISABLED'
                // );

                this._loggerService.info(notification.title, notification.body, {
                    autoClose: false,
                    icon: '🔔'
                });

                return; // Do NOT show system notification
            }
        });
    }

    private getNotificationObject(event: MessagePayload) {
        return this.getDataObject(event.data);
    }

    private getDataObject(data: any): any {
        if (data?.data) {
            return this.getDataObject(data.data);
        }
        return data;
    }

    // Check if push notifications are supported
    private isPushSupported(): boolean {
        return 'serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    private async fetchFCMToken(silentRequest: boolean): Promise<NotificationPermission> {
        if (!this.messaging) {
            this.logMessageNotificationsDisabled();
            return 'denied';
        }

        // Get existing service worker registration instead of registering again
        const registration = await navigator.serviceWorker.getRegistration();
        const currentToken = await getToken(this.messaging, {
            vapidKey: environment.vapidKey,
            serviceWorkerRegistration: registration,
        });

        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            const _user = this._userService.user();
            if (_user && !_user.tokens.find((item) => item === currentToken)) {
                return new Promise((resolve) => {
                    this._userService.subscribeWithToken(
                        currentToken,
                        (errorResponse: ErrorResponse) => {
                            if (!silentRequest) {
                                const internalServerErrorMessage = this._translocoService.translate(
                                    'COMMON.ERROR.INTERNAL_SERVER_ERROR'
                                );
                                this._loggerService.error(
                                    null,
                                    internalServerErrorMessage,
                                    null,
                                    this._configService.config().scheme
                                );
                            }
                            resolve('denied');
                        },
                        () => {
                            if (!silentRequest) {
                                const message = this._translocoService.translate(
                                    'TRADING_PAIR.ALERT.NOTIFICATION.SUBSCRIBED'
                                );
                                this._loggerService.success(null, message);
                            }
                            resolve('granted');
                        }
                    );
                });
            } else {
                // Show permission request UI
                // No registration token available. Request permission to generate one.
                if (!silentRequest) {
                    this.logMessageNotificationsDisabled();
                }

                return 'denied';
            }
        }
    }

    private logMessageNotificationsDisabled() {
        const disabledMessage = this._translocoService.translate(
            'TRADING_PAIR.ALERT.NOTIFICATION.DISABLED'
        );

        this._loggerService.error(null, disabledMessage, null, this._configService.config().scheme);
    }
}
